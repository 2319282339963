import { Button, Flex, Loader, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, forwardRef, lazy, useCallback, useEffect, useState } from "react";

import {
  ItemOption,
  useItemsAutoComplete,
  useItemsCode,
} from "@/custom/features/ui/autoComplete/item/auto-useGetItemsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";

import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { GradesNameAutoComplete } from "@/features/ui/autoComplete/grade/grades-autoComplete-name";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import { ItemTypesMultiAutoComplete } from "@/features/ui/multiAutoComplete/itemType/itemType-multiAutoComplete";
import { useForm } from "@mantine/form";
import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { debounce } from "es-toolkit";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  group?: string;
  label: string;
  value: string;
  spec?: string;
}

export interface ItemAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[];
}

// 입력값: value (item 모델의 code)
// 출력값: onChange (item 모델의 code)

const ItemsCreateForm = lazy(() => import('@/features/item/components/form/ItemsCreateForm').then(module => ({ default: module.ItemsCreateForm })));

export const ItemAutoComplete = (params: ItemAutoCompleteProps) => {
  const {
    value: itemCode,
    onChange,
    maxDropdownHeight,
    width,
    getItemType,
    ...etcParams
  } = params;

  const form = useForm({
    initialValues: {
      itemType: getItemType && getItemType.length ? getItemType : [],
      gradeName: "",
      itemCategoryCode: "",
      abstractItemCode: "",
    }
  });

  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [debouncedKeyword, setDebouncedKeyword] = useState<string>(() => searchKeyword);
  const { data: options, isLoading: optionLoading } = useItemsAutoComplete(
    focused,
    debouncedKeyword,
    form.values,
    getItemType,
  );

  const { data: initialOptions, isLoading } = useItemsCode(!!itemCode, itemCode ?? null);

  const selectOptions = [...(options ?? []), ...(initialOptions ?? [])].reduce(
    (unique: ItemProps[], option: ItemProps) => {
      return unique.some((u) => u.value === option.value)
        ? unique
        : [...unique, option];
    },
    []
  );

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ group, label: name, value: code, ...others }, ref) => {
      return (
        <div ref={ref} {...others}>
          <Flex direction="row" justify="space-between" align="center">
            <Flex direction={"column"}>
              <Text>{name}</Text>
              {/* <Text size={"xs"} color="#666666"> */}
              {/* Grade: {grade}, Color: {color}, 형태: {shape} */}
              {/* </Text> */}
            </Flex>
            <Text fz="xs">{group}</Text>
          </Flex>
        </div>
      )
    }
  );

  let selectedItem = initialOptions?.find((item) => item.value === itemCode);

  const onChangeHandler = (e: string | null) => {
    selectedItem = options?.find((item) => item.value === e);
    onChange?.(e);
  };
  const debouncedSearch = useCallback(
    debounce((keyword) => {
      setDebouncedKeyword(keyword);
    }, 1000), // 1000ms 지연 시간
    []
  );

  // searchKeyword가 변경될 때마다 debounce 함수 호출
  useEffect(() => {
    debouncedSearch(searchKeyword);
  }, [searchKeyword, debouncedSearch]);

  return (
    <Flex direction="column" w="100%" gap="sm">
      <ItemSelect
        withinPortal
        onDropdownOpen={() => setFocused(true)}
        onDropdownClose={() => setFocused(false)}
        clearable
        value={itemCode}
        itemComponent={SelectItem}
        searchValue={searchKeyword}
        data={selectOptions}
        searchable
        maxDropdownHeight={maxDropdownHeight ?? 150}
        onChange={onChangeHandler}
        onSearchChange={setSearchKeyword}
        rightSection={ItemInfo({
          item: selectedItem as ItemOption,
          onChange: onChangeHandler,
        })}
        filter={(value, item) =>
          item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          item?.value?.toLowerCase().includes(value.toLowerCase().trim())
        }
        nothingFound={AddNewItem({
          itemName: searchKeyword,
          onChange: onChangeHandler,
          isLoading: isLoading,
          optionLoading: optionLoading,
        })}
        {...etcParams}
      />
      <Flex w="100%" gap="sm">
        <ItemTypesMultiAutoComplete w="50%" label="품목군" disabled={getItemType && getItemType?.length > 0} {...form.getInputProps("itemType")} />
        <GradesNameAutoComplete w="50%" label="Grade" {...form.getInputProps("gradeName")} />
      </Flex>
      <Flex w="100%" gap="sm">
        <ItemCategoryAutoComplete w="50%" label="Color" {...form.getInputProps("itemCategoryCode")} />
        <AbstractItemAutoComplete w="50%" label="형태" {...form.getInputProps("abstractItemCode")} />
      </Flex>
    </Flex>

  );
};

const ItemInfo = (params: {
  item?: ItemOption;
  onChange: (itemCode: string | null) => void;
}) => {
  const { item, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return item?.value ? (
    <ItemInfoLabel>
      {/* ({item.value}) */}
      <IconX size="1rem" onClick={clearHandler} />
    </ItemInfoLabel>
  ) : null;
};

const AddNewItem = (params: {
  itemName: string;
  onChange: (itemCode: string) => void;
  isLoading?: boolean;
  optionLoading?: boolean;
}) => {
  const { itemName, onChange, isLoading, optionLoading } = params;
  const { openModal } = useModal();

  const onCloseHandler: EventHandler<any> = (values) => {
    console.log(values);
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(<ItemsCreateForm />, null, "신규 품목 등록", true).then(
      (result) => {
        onChange(result?.code);
      }
    );
  };

  return (
    <Button variant="subtle" onClick={(e) => !!itemName && addNewModalHandler(e)}>
      {isLoading || optionLoading ?
        <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
          <Flex justify="center" align="center" gap="sm">
            <Text>Not Found</Text>
            <Loader size="sm" />
          </Flex>
        </div> : `신규 품목(${itemName}) 등록`}

    </Button>
  );
};
const ItemInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  // right: 30px;
  position: absolute;
`;

const ItemSelect = styled(Select) <{ value: string | null | undefined }>`
  .mantine-Select-input {
    font-size: 0.8rem;
    // padding-bottom: ${(props) => (props.value ? '0.8rem !important' : '0.4rem !important')};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
