import { grades } from "@/api/grade/useGradesQuery";
import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import { Badge, Checkbox, Flex, Space, Text, Title } from "@mantine/core";
import { ItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";


export const ItemView = (params: {
    itemData: Partial<ItemsGet200ResponseRowsInner>;
}) => {
    const {
        name,
        itemType,
        itemCategoryName,
        abstractItemCode,
        usesLotName,
        usesLotExpiration,
        unitText,
        gradeCode
    } = params.itemData;

    const { t } = useTranslation();

    const { openModal } = useModal();

    // grade Code가 unique한 값은 아니지만, unique하게 사용하고 있기 때문에 이렇게 코드로 name을 조회할 수 있도록 처리해 놓았음.
    const { data: grade } = useQuery(grades.get({ query: { code: gradeCode } }));
    const gradeName = grade?.data?.rows?.[0].name;

    return (
        <Flex w="100%" p="md" bg={theme.colors?.gray?.[0]} direction="column" gap="0.5rem">
            <Title>품목 정보 확인</Title>
            <Space h="md" />
            <Flex w="100" gap="lg" align="center" justify="space-between">
                <Flex direction="column" gap="sm" justify="center" align="center">
                    <Text fw={700} c="dimmed">
                        품목군
                    </Text>
                    <Badge color={itemTypeColor[itemType!]}>{t(itemType!)}</Badge>
                </Flex>
                <Flex direction="column" gap="sm" justify="center" align="center">
                    <Text fw={700} c="dimmed">
                        품목명
                    </Text>
                    <Text fz="sm">{name}</Text>
                </Flex>
                <Flex direction="column" gap="sm" justify="center" align="center">
                    <Text fw={700} c="dimmed">
                        Grade
                    </Text>
                    <Text fz="sm">{gradeName}</Text>
                </Flex>
                <Flex direction="column" gap="sm" justify="center" align="center">
                    <Text fw={700} c="dimmed">
                        Color
                    </Text>
                    {
                        itemCategoryName ?
                            <DetailLink
                                justify="flex-start"
                                onClick={() => openModal(
                                    <ItemCategoryDetailForm ItemCategoryName={itemCategoryName} />,
                                    null,
                                    ""
                                )}
                            >
                                {itemCategoryName}
                            </DetailLink> : <Text>&nbsp;</Text>
                    }
                </Flex>
                <Flex direction="column" gap="sm" justify="center" align="center">
                    <Text fw={700} c="dimmed">
                        형태
                    </Text>
                    {
                        abstractItemCode ?
                            <DetailLink
                                justify="flex-start"
                                onClick={() => openModal(
                                    <AbstractItemDetailForm AbstractItemCode={abstractItemCode} />,
                                    null,
                                    ""
                                )}
                            >
                                {abstractItemCode}
                            </DetailLink> : <Text>&nbsp;</Text>
                    }
                </Flex>
                <Flex direction="column" gap="sm" justify="center" align="center">
                    <Text fw={700} c="dimmed" >
                        단위
                    </Text>
                    <Text>{unitText}</Text>
                </Flex>
                <Flex direction="column" gap="sm" justify="space-between" align="center" h="100%">
                    <Text fw={700} c="dimmed" w="12rem">
                        재고 LOT 관리 기준
                    </Text>
                    <Flex justify="space-between" align="center" gap="xl">
                        <Checkbox
                            label="이름"
                            defaultChecked={usesLotName}
                            disabled
                            size="1.45rem"
                        />
                        <Checkbox
                            label="유효기한"
                            defaultChecked={usesLotExpiration}
                            disabled
                            size="1.45rem"
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
