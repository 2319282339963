import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, forwardRef, useState } from "react";

import { useModal } from "@/features/modal/ModalStackManager";
import {
    GradesOption,
    useGradeName,
    useGradesNameAutoComplete
} from "@/features/ui/autoComplete/grade/auto-useGetGradesQuery";
import styled from "@emotion/styled";

import { mutateGrades } from "@/api/grade/useGradesQuery";
import { GradeCreateForm } from "@/features/grade/components/form/GradeCreateForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { MasterApiGradesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import customAlert from "../../alert/alert";
interface GradesProps extends React.ComponentPropsWithoutRef<"div"> {
    // group: string;
    label: string;
    value: string;
}

export interface GradesAutoCompleteProps
    extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    width?: string;
    value?: string | null;
    onChange?: (gradesName: string | null) => void;
    setGradeName?: (name: string) => void;
    maxDropdownHeight?: number;
}

// 입력값: value (Grades 모델의 code)
// 출력값: onChange (Grades 모델의 code)

export const GradesNameAutoComplete = (params: GradesAutoCompleteProps) => {
    const {
        width,
        value: gradesName,
        onChange,
        maxDropdownHeight,
        setGradeName,
        ...etcParams
    } = params;
    const [focused, setFocused] = useState<boolean>(true);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const { data: options } = useGradesNameAutoComplete(focused, searchKeyword);

    const { data: initialOptions } = useGradeName(!!gradesName, gradesName ?? null);

    let selectedGrades = initialOptions?.find(
        (Grades) => Grades.value === gradesName
    );
    const onChangeHandler = (e: string | null) => {
        selectedGrades = options?.find((Grades) => Grades.value === e);
        setGradeName && setGradeName(selectedGrades?.label ?? "");
        onChange && onChange(e);
    };

    const SelectGrades = forwardRef<HTMLDivElement, GradesProps>(
        ({ label: name, value: code, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Flex direction="row" justify="space-between" align="center">
                    <Flex align={"center"}>
                        <Text>{name}</Text>
                        <Text fz="xs">(코드: {code})</Text>
                    </Flex>
                    {/* <Text fz="xs">{group}</Text> */}
                </Flex>
            </div>
        )
    );

    return (
        <GradeSelect
            withinPortal
            onDropdownOpen={() => setFocused(true)}
            onDropdownClose={() => setFocused(false)}
            clearable
            // inputContainer={(children: ReactNode) => (
            //     <div style={{ position: "relative" }}>
            //         {children}
            //         {selectedGrades?.value && (
            //             <div
            //                 style={{
            //                     position: "absolute",
            //                     bottom: "-2px",
            //                     fontSize: "0.8em",
            //                     color: "#888",
            //                     left: "10px",
            //                 }}
            //             >
            //                 {"Code: " + selectedGrades?.value}
            //             </div>
            //         )}
            //     </div>
            // )}
            value={gradesName}
            // itemComponent={SelectGrades}
            searchValue={searchKeyword}
            data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
                (unique: GradesProps[], option: GradesProps) => {
                    return unique.some((u) => u.value === option.value)
                        ? unique
                        : [...unique, option];
                },
                []
            )}
            searchable
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onChange={onChangeHandler}
            onSearchChange={setSearchKeyword}
            rightSection={GradesInfo({
                Grades: selectedGrades as GradesOption,
                onChange: onChangeHandler,
            })}
            filter={(value, item) =>
                item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                item?.value?.toLowerCase().includes(value.toLowerCase().trim())
            }
            nothingFound={AddNewGrades({
                gradesName: searchKeyword,
                onChange: onChangeHandler,
            })}
            {...etcParams}
        />
    );
};

const GradesInfo = (params: {
    Grades?: GradesOption;
    onChange: (gradesName: string | null) => void;
}) => {
    const { Grades, onChange } = params;

    const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange(null);
    };

    return Grades?.value ? (
        <GradesInfoLabel>
            {/* ({Grades.value}) */}
            <IconX size="1rem" onClick={clearHandler} />
        </GradesInfoLabel>
    ) : null;
};

const AddNewGrades = (params: {
    gradesName: string;
    onChange: (gradesName: string) => void;
}) => {
    const { gradesName, onChange } = params;
    const { openModal, closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
        (params: MasterApiGradesPostRequest) =>
            mutateGrades.create(params).mutationFn(params as MasterApiGradesPostRequest | any),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries(['grades']);
                customAlert(
                    "등급 생성에 성공하였습니다.",
                    "생성 성공",
                    "green"
                )
                closeModal(res.data);
            },
            onError: (error) => {
                customAlert(handleErrorResponse({ error, errorContext: '등급코드' }), '등급 생성 실패', 'red')
            }
        }
    );

    const onCloseHandler: EventHandler<any> = (values) => {
        mutate({
            gradesGetRequest: {
                code: values.code!,
                name: values.name!,
            },
        });
    };
    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <GradeCreateForm name={gradesName} autoCompleteOnSubmit={onCloseHandler} />,
            null,
            "신규 등급 등록",
            true
        ).then((result) => {
            onChange(result?.code);
        });
    };

    return (
        <Button variant="subtle" onClick={addNewModalHandler}>
            신규 등급({gradesName}) 등록
        </Button>
    );
};
const GradesInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const GradeSelect = styled(Select) <{ value: string | undefined | null }>`
  .mantine-Select-input {
    font-size: 0.8rem;

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`