import { mutateItemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ItemCategoriesGet200ResponseRowsInner, MasterApiItemCategoriesItemCategoryIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const ItemCategoryUpdateForm = (params: {
  formatterProps: ItemCategoriesGet200ResponseRowsInner;
}) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiItemCategoriesItemCategoryIdPutRequest) =>
      mutateItemCategories.update(params).mutationFn(params as MasterApiItemCategoriesItemCategoryIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['itemCategories']);
        closeModal(formatterProps);
        customAlert(
          "품목분류 수정에 성공하였습니다.",
          "수정 성공",
          "green"
        )
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: '품목분류' }), '품목분류 수정 실패', 'red')
      }
    }
  );

  const form = useForm({
    initialValues: {
      name: formatterProps.name,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate(
        {
          itemCategoryId: formatterProps.id as number,
          itemCategoriesGetRequest: {
            name: form.values.name!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert(
                "Color 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              )
            } else {
              customAlert(
                "Color 수정에 실패하였습니다.",
                "수정 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "Color 수정에 실패하였습니다.",
        "수정 실패",
        "red"
      )
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input
        label="Color"
        withAsterisk
        defaultValue={formatterProps?.name}
        {...form.getInputProps("name")}
        error={form.isDirty("name") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("name") ? "red !important" : "",
            borderColor: form.isDirty("name")
              ? "red !important"
              : "",
          },
          error: { fontSize: 11, color: "red" },
        }}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          취소
        </Update.Button>
        <Update.Button
          color="blue"
          disabled={validateFormValues({
            pageType: "itemCategory",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          Color 수정
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
