import { useQuery } from "@tanstack/react-query";

import DefaultInstance from "@/instance/axios";
import { ItemGradeViewGet200ResponseRowsInner, ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

type FormValues = {
  itemType: string;
  gradeCode: string;
  itemCategoryCode: string;
  abstractItemCode: string;
};

export type ItemOption = {
  value: string;
  label: string;
  group: string;
};
const getAutoCompleteKeyword = (
  keyword: string,
  formValues: FormValues,
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[],
) =>
  // MasterInstance.itemsFindPost(
  DefaultInstance.itemGradeViewFindPost(
    {
      query: {
        $and: [
          {
            "itemType": {
              "$in": [formValues.itemType]
            }
          },
          {
            "gradeCode": {
              "$like": `%${formValues.gradeCode}%`
            }
          },
          {
            "itemCategoryCode": {
              "$like": `%${formValues.itemCategoryCode}%`
            }
          },
          {
            "abstractItemCode": {
              "$like": `%${formValues.abstractItemCode}%`
            }
          }
        ]
      },
      search: keyword,
      searchFields: ["name", "code"],
      populate: ["itemCategory"],
    },
    {
      params: {
        ...(!keyword && { limit: 50 }),
        query: {
          itemType: getItemType
        },
      },
    }
  ).then((res: AxiosResponse<ItemGradeViewGet200ResponseRowsInner[]>) => res.data);

export const useItemsAutoComplete = (
  focus = false,
  keyword = "",
  formValues: FormValues,
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[],
  options?: {
    onSuccess: (data: ItemOption[]) => void;
  }
) => {
  const { t } = useTranslation();

  return useQuery(
    ["items-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword, formValues, getItemType),
    {
      enabled: focus,
      select: (data: ItemGradeViewGet200ResponseRowsInner[]) => {
        return data?.map((item) => {
          return {
            value: item.code,
            label: `품명: ${item.name}, Grade: ${item.gradeName ?? "x"}, Color: ${item.itemCategoryName ?? "x"}, 형태: ${item.abstractItemCode ?? "x"}`,
            spec: item.spec,
            group: t(item.itemType),
          };
        }) ?? [];
      },
      onSuccess: options?.onSuccess,
    }
  );
};

const getItemByCode = (code: string | null) =>
  DefaultInstance.itemGradeViewFindPost(
    {
      ...(code && { search: code }),
      searchFields: ["code"],
    },
    {
      ...(!code && { limit: 50 }),
      params: {
        query: {},
      },
    }
  ).then((res: AxiosResponse<ItemGradeViewGet200ResponseRowsInner[]>) => res.data);

export const useItemsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: ItemOption[]) => void;
  }
) => {
  const { t } = useTranslation();

  return useQuery(["items-get-code", code], () => getItemByCode(code), {
    enabled: enabled ?? false,
    select: (data: ItemGradeViewGet200ResponseRowsInner[]) =>
      data?.map((item) => {
        return {
          value: item.code,
          label: `품명: ${item.name}, Grade: ${item.gradeName ?? "x"}, Color: ${item.itemCategoryName ?? "x"}, 형태: ${item.abstractItemCode ?? "x"}`,
          group: t(item.itemType),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};

