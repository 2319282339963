import { consumeTypeColor } from "@/constants/routings";
import { ItemAutoComplete } from "@/custom/features/ui/autoComplete/item/item-autoComplete";
import styled from "@emotion/styled";
import { Badge, Button, Flex, NumberInput, Radio, Table, Text, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ItemsOneStepCreatePostRequestRoutingsInner, ItemsOneStepCreatePostRequestRoutingsInnerRoutingBomsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconTrashX } from "@tabler/icons-react";
import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { RoutingFormProps } from "../ItemsCreateForm";

interface RoutingsListProps {
  formData: UseFormReturnType<Partial<RoutingFormProps>>;
}

export const RoutingsList = ({ formData }: RoutingsListProps) => {
  const { t } = useTranslation();
  const [routingIndex, setRoutingIndex] = useState<number>(0);

  const handleCheckRadio: ChangeEventHandler<HTMLInputElement> = (e) => {
    setRoutingIndex(Number(e.target.value));
  };

  const handleClickBomAdd = (routingIndex: number) => {
    formData.insertListItem(`routingRows.${routingIndex}.routingBoms`, {
      itemCode: "",
      quantity: 0,
    });
  }

  const handleClickBomDelete = (bomIndex: number) => {
    formData.removeListItem(`routingRows.${routingIndex}.routingBoms`, bomIndex);
  }

  return (
    <Flex direction="column" w="100%" h="100%" gap="md">
      <Flex w="100%">
        <Table withBorder withColumnBorders>
          <thead>
            <tr>
              <Th width={6}>선택</Th>
              <Th width={12}>시퀀스</Th>
              <Th width={12}>공정</Th>
              <Th width={12}>소모 시점</Th>
            </tr>
          </thead>
          <tbody>
            {
              formData.values.routingRows &&
              formData.values.routingRows.map((data: ItemsOneStepCreatePostRequestRoutingsInner, i: number) => {
                return (
                  <tr key={data.code ?? "" + i}>
                    <td><Radio name="routing" value={i} checked={routingIndex === i} onChange={(e) => handleCheckRadio(e)} /></td>
                    <td>{data.seq}</td>
                    <td>{data.operationCode}</td>
                    <td><Badge color={consumeTypeColor[data.consumeType!]}>{t(data.consumeType ?? "")}</Badge></td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </Flex>
      <Flex direction="column" gap="xs" w="100%">
        <Flex direction="column" gap="xs">
          <Title order={2}>라우팅 BOM 입력</Title>
          <Flex direction="column">
            <Text fz="xs">
              선택된 라우팅이 진행될 때 소모될 원/부자재 및 반제품 수량을 입력합니다. 소모할 원/부자재 및 반제품이 없다면 입력하지 않아도 됩니다.
            </Text>
          </Flex>
        </Flex>
        <Table withBorder withColumnBorders>
          <thead>
            <tr>
              <Th width={55}>원부자재</Th>
              <Th width={35}>수량</Th>
              <Th width={10}>비고</Th>
            </tr>
          </thead>
          <tbody>
            {
              formData.values.routingRows &&
              formData.values.routingRows[routingIndex].routingBoms &&
              formData.values.routingRows[routingIndex].routingBoms?.map((data: ItemsOneStepCreatePostRequestRoutingsInnerRoutingBomsInner, bomIndex: number) => {
                return (
                  <tr key={routingIndex + bomIndex}>
                    <td>
                      <ItemAutoComplete
                        {...formData.getInputProps(
                          `routingRows.${routingIndex}.routingBoms.${bomIndex}.itemCode`
                        )}
                        getItemType={["MATERIAL", "SUBSIDIARY", "SEMI_PRODUCT"]}
                      />
                      {/* <MaterialAutoComplete
                        {...formData.getInputProps(
                          `routingRows.${routingIndex}.routingBoms.${bomIndex}.itemCode`
                        )}
                      /> */}
                    </td>
                    <td>
                      <NumberInput
                        {...formData.getInputProps(
                          `routingRows.${routingIndex}.routingBoms.${bomIndex}.quantity`
                        )}
                      />
                    </td>
                    <td>
                      <Button
                        leftIcon={<IconTrashX />}
                        disabled={
                          !(
                            formData.values.routingRows &&
                            formData.values.routingRows.length &&
                            formData.values.routingRows[routingIndex] &&
                            formData.values.routingRows[routingIndex].routingBoms &&
                            formData.values.routingRows[routingIndex].routingBoms?.length &&
                            (formData.values.routingRows[routingIndex].routingBoms?.length ?? 0) > 1
                          )}
                        onClick={() => handleClickBomDelete(bomIndex)}
                        color="red"
                        w="100%"
                      >
                        삭제
                      </Button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
        <Flex w="100%" justify="center">
          <Button
            variant="outline"
            color="dark"
            leftIcon={<IconPlus />}
            onClick={() => handleClickBomAdd(routingIndex)}
          >
            새로운 BOM 추가
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;