import { abstractItems } from "@/api/abstractItems/useAbstractItemsQuery";
import { defects } from "@/api/defect/useDefectsQuery";
import { wmsLog } from "@/api/delivery/useDeliveryQuery";
import { department } from "@/api/department/useDepartmentQuery";
import { downtimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { equipments } from "@/api/equipment/useEquipmentQuery";
import { grades } from "@/api/grade/useGradesQuery";
import { inspections } from "@/api/inspect/inspectSpecification/useInspectionQuery";
import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { inventories } from "@/api/inventories/useInventoriesQuery";
import { invoice } from "@/api/invoiceStatement/useInvoiceQuery";
import { itemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { ledgers } from "@/api/ledgers/useLedgersQuery";
import { locations } from "@/api/locations/useLocationsQuery";
import { molds } from "@/api/mold/useMoldQuery";
import { moldStocks } from "@/api/moldStock/useMoldStockQuery";
import { operations } from "@/api/operations/useOperationsQuery";
import { partners } from "@/api/partner/usePartnerQuery";
import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { routings } from "@/api/routing/useRoutingQuery";
import { routingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { sites } from "@/api/sites/useSitesQuery";
import { users } from "@/api/users/useUsersQuery";
import { wipInventory } from "@/api/wipInventories/useWipInventoriesQuery";
import { customFunctions } from "@/config/customFunction";
import { abstractItemsHeader, defectsHeader, departmentHeader, downtimeReasonsHeader, equipmentsHeader, gradesHeader, inventoriesHeader, invoiceStatementHeader, itemCategoriesHeader, itemsBomHeader, itemsHeader, LedgersHeader, locationsHeader, moldsHeader, moldStocksHeader, operationsHeader, orderHeader, partnersHeader, routingOutsourcesHeader, routingsHeader, sitesHeader, specificationsHeader, usersHeader, wipInventoriesHeader, wmsLogsHeader } from "@/constants/columnHeader";
import ProtectedRoute from "@/features/Router/ProtectedRoute";
import { Layout } from "@/features/layout/Template";
import { LotTrackingProvider } from "@/features/lotTracking/context/LotTrackingProvider";
import ModalExample from "@/features/modal/example";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { StandardLayoutProvider } from "@/features/standardLayout/Provider";
import { CheckboxProvider } from "@/features/workByManagement/context/CheckboxProvider";
import { BOMListPage } from "@/pages/BomList";
import { AbstractItemPage } from "@/pages/abstractItem";
import { ALCModelPage } from "@/pages/alcModel";
import { AnalysisAbilityPage } from "@/pages/analysis/ability";
import { AnalysisErrorPage } from "@/pages/analysis/error";
import { AnalysisIdleTimePage } from "@/pages/analysis/idleTime";
import { AnalysisOutputPage } from "@/pages/analysis/output";
import { AnalysisPowerPage } from "@/pages/analysis/power";
import { AnalysisTotalOutputPage } from "@/pages/analysis/totaloutput";
import { AnalysisWorkTimePage } from "@/pages/analysis/workTime";
import { AnalysisMonitoringPage } from "@/pages/analysisMonitoring";
import { AuthorityPage } from "@/pages/authority";
import { BarcodeLiftPage } from "@/pages/barcodeLift";
import { BarcodeMergePage } from "@/pages/barcodeMerge";
import { BOMPage } from "@/pages/bom";
import { BOMSearchPage } from "@/pages/bomSearch";
import { ChangePointPage } from "@/pages/changePoint";
import { ChangePointListPage } from "@/pages/changePointList";
import { CommonCodePage } from "@/pages/commonCode";
import { DailyListPage } from "@/pages/dailyList";
import { DailyRegisterPage } from "@/pages/dailyRegister";
import { MainDashboard } from "@/pages/dashboard";
import { DataDisplayPage } from "@/pages/dataDisplay";
import { Defects } from "@/pages/defects";
import { DeliveryListCompanyPage } from "@/pages/deliveryListCompany";
import { DeliveryListHeadOfficePage } from "@/pages/deliveryListHeadOffice";
import { DeliveryListSupplierPage } from "@/pages/deliveryListSupplier";
import { DeliveryStatusPage } from "@/pages/deliveryStatus";
import { DeliveryStatusHeadOfficePage } from "@/pages/deliveryStatusHeadOffice";
import { DeliveryStatusSupplierPage } from "@/pages/deliveryStatusSupplier";
import { DepartmentPage } from "@/pages/department";
import { DocumentFilePage } from "@/pages/documentFile";
import { DowntimeReasonsPage } from "@/pages/downtimeReasons";
import { DownTimeReasonsLogPage } from "@/pages/downtimeReasonsLog";
import { Equipment } from "@/pages/equipment";
import { EquipmentAnalysisPage } from "@/pages/equipmentAnalysis";
import { EquipmentProblemPage } from "@/pages/equipmentProblem";
import { ErrorPage } from "@/pages/error";
import { ErrorViewPage } from "@/pages/errorView";
import { GradePage } from "@/pages/grade";
import { IdleTimePage } from "@/pages/idleTime";
import { InspectSpecificationPage } from "@/pages/inspect";
import { InspectionRecordsPage } from "@/pages/inspectionRecords";
import { InventoryPage } from "@/pages/inventory";
import { InventoryLifePage } from "@/pages/inventoryLife";
import { InvoiceStatementCompletePage } from "@/pages/invoiceComplete";
import { InvoiceStatementPage } from "@/pages/invoiceStatement";
import { Item } from "@/pages/item";
import { ItemCategoryPage } from "@/pages/itemCategory";
import { KPIDefectPage } from "@/pages/kpi/defect";
import { KPILeadtimePage } from "@/pages/kpi/leadtime";
import { KPILeadTimeOrderPage } from "@/pages/kpi/leadtimeOrder";
import { KPIManhourPage } from "@/pages/kpi/manhour";
import { KPIPowerUsagePage } from "@/pages/kpi/powerUsage";
import { KPIRateOfOperationPage } from "@/pages/kpi/rateOfOperation";
import { KPIUPHPage } from "@/pages/kpi/uph";
import { LabelSupplierPage } from "@/pages/labelSupplier";
import { LackSafetyPage } from "@/pages/lackSafety";
import { LedgerAllPage } from "@/pages/ledgersAll";
import { LocationPage } from "@/pages/location";
import Login from "@/pages/login";
import { LotTrackingForwardPage } from "@/pages/lotTrackingForward";
import { LotTrackingRevercePage } from "@/pages/lotTrackingReverce";
import { MachinePage } from "@/pages/machine";
import { MaintenancePage } from "@/pages/maintenance";
import { MaintenanceCompletePage } from "@/pages/maintenanceComplete";
import { MaintenanceListPage } from "@/pages/maintenanceList";
import { FormExample } from "@/pages/modalExample/form";
import WizardForm from "@/pages/modalExample/wizard";
import { MoldPage } from "@/pages/mold";
import { MoldCompleteAdminPage } from "@/pages/moldManagement/completeForAdmin";
import { MoldMaintenancePage } from "@/pages/moldManagement/maintenance";
import { MoldDailyRegisterPage } from "@/pages/moldManagement/moldDailyRegister";
import { MoldDailyStatusPage } from "@/pages/moldManagement/moldDailyStatus";
import { MoldProblemPage } from "@/pages/moldManagement/problem";
import { MoldRepairsPage } from "@/pages/moldManagement/repairs";
import { MoldStockPage } from "@/pages/moldStock";
import { MonitoringPage } from "@/pages/monitoring";
import { NotFound } from "@/pages/notFound";
import { WorkByOperationOutsource } from "@/pages/operationOutsourceWorkByRow";
import { Operations } from "@/pages/operations";
import { OrderPage } from "@/pages/order";
import { OrderCompletePage } from "@/pages/orderComplete";
import { OrderHeadOfficePage } from "@/pages/orderHeadOffice";
import { OrderItemsPage } from "@/pages/orderItems";
import { OutputPage } from "@/pages/output";
import { OverViewPage } from "@/pages/overview";
import { PartnerPage } from "@/pages/partner";
import { PeripheralPage } from "@/pages/peripheral";
import { PowerPage } from "@/pages/power";
import { PressCamViewPage } from "@/pages/press/camview";
import { PressClutchAndBrakePage } from "@/pages/press/clutchandbrake";
import { PressCompleteAdminPage } from "@/pages/press/completeAdmin";
import { PressErrorViewPage } from "@/pages/press/errorview";
import { PressFacilitiesPage } from "@/pages/press/facilities";
import { PressParameterViewPage } from "@/pages/press/parameterview";
import { PressDailyRegisterPage } from "@/pages/press/pressDailyRegister";
import { PressDailyStatusPage } from "@/pages/press/pressDailyStatus";
import { PressProblemPage } from "@/pages/press/problem";
import { PressMonitoringFactoryPage } from "@/pages/pressMonitoring/factory";
import { FactoryPageSetting } from "@/pages/pressMonitoring/factory/pageSetting";
import { ProductionInspectionPage } from "@/pages/productionInspection";
import { ProductionPerformancePage } from "@/pages/productionPerformance";
import { ProductionPerformanceOutsourcingPage } from "@/pages/productionPerformanceOutsourcing";
import { PurchaseOrderPage } from "@/pages/purchaseOrder";
import { PurchaseOrderItemsPage } from "@/pages/purchaseOrderItems";
import { PurchaseOrderItemsHeadOfficePage } from "@/pages/purchaseOrderItemsHeadOffice";
import { PurchaseOrderItemsSupplierPage } from "@/pages/purchaseOrderItemsSupplier";
import { ChangeListPage } from "@/pages/quality/changeList";
import { DefectStatisticsPage } from "@/pages/quality/defectStatistics";
import { WorkStandardListPage } from "@/pages/quality/workStandardList";
import { ReceiptPage } from "@/pages/receipt";
import { ReceiptSupplierPage } from "@/pages/receiptSupplier";
import { ReceivingInspectionPage } from "@/pages/receivingInspection";
import { Routing } from "@/pages/routing";
import { RoutingOutsourcePage } from "@/pages/routingOutsource";
import { ShipmentReceiptPage } from "@/pages/shipmentReceipt";
import { ShipmentRequestPage } from "@/pages/shipmentRequest";
import { ShipmentRequestSupplierPage } from "@/pages/shipmentRequestSupplier";
import { ShipmentReturnPage } from "@/pages/shipmentReturn";
import { ShipmentStatusPage } from "@/pages/shipmentStatus";
import { ShipmentStatusSupplierPage } from "@/pages/shipmentStatusSupplier";
import { Site } from "@/pages/site";
import { StockPlanPage } from "@/pages/stockPlan";
import { SystemPage } from "@/pages/system";
import { SystemLogsPage } from "@/pages/systemLogs";
import { SystemParameterPage } from "@/pages/systemParameter";
import { StandardInfoBestPracticePage } from "@/pages/test/StandardInfoBestPractice";
import { TransportationPage } from "@/pages/transportation";
import { UserLogsPage } from "@/pages/userLogs";
import { Users } from "@/pages/users";
import { WipInventoryPage } from "@/pages/wipInventory";
import { WmsLogsPage } from "@/pages/wmsLogs";
import { WorkByEquipment } from "@/pages/workByEquipment";
import { WorkByProductionPlan } from "@/pages/workByProductionPlan";
import { WorkByRows } from "@/pages/workByRows";
import { WorkCompletePage } from "@/pages/workComplete";
import { WorkLogPage } from "@/pages/workLog";
import { WorkStandardPage } from "@/pages/workStandard";
import { WorkTimePage } from "@/pages/workTime";
import { useCheckAuth } from "@/utils/checkAuth";

import { Route, Routes, useLocation } from "react-router-dom";

// PMS 메뉴 Routes
const pmsMenuRoutes = [
  { path: "/analysis/output", element: <AnalysisOutputPage /> },
  { path: "/analysis/ability", element: <AnalysisAbilityPage /> },
  { path: "/analysis/totaloutput", element: <AnalysisTotalOutputPage /> },
  { path: "/analysis/error", element: <AnalysisErrorPage /> },
  { path: "/analysis/power", element: <AnalysisPowerPage /> },
  { path: "/analysis/idleTime", element: <AnalysisIdleTimePage /> },
  { path: "/analysis/workTime", element: <AnalysisWorkTimePage /> },
  { path: "/press/errorview", element: <PressErrorViewPage /> },
  { path: "/press/parameterview", element: <PressParameterViewPage /> },
  { path: "/press/camview", element: <PressCamViewPage /> },
  { path: "/press/clutchandbrake", element: <PressClutchAndBrakePage /> },
  { path: "/press/facilities", element: <PressFacilitiesPage /> },
  { path: "/press/complete/admin", element: <PressCompleteAdminPage /> },
  { path: "/press/problem", element: <PressProblemPage /> },
  { path: "/press/PressDailyStatus", element: <PressDailyStatusPage /> },
  { path: "/press/PressDailyRegister", element: <PressDailyRegisterPage /> },
  { path: "/mold/maintenance", element: <MoldMaintenancePage /> },
  { path: "/mold/repairs", element: <MoldRepairsPage /> },
  { path: "/mold/complete/admin", element: <MoldCompleteAdminPage /> },
  { path: "/mold/problem", element: <MoldProblemPage /> },
  { path: "/mold/moldDailyRegister", element: <MoldDailyRegisterPage /> },
  { path: "/mold/moldDailyStatus", element: <MoldDailyStatusPage /> },
];

// KPI 메뉴 Routes
const kpiMenuRoutes = [
  { path: "/kpi/leadtime", element: <KPILeadtimePage /> },
  { path: "/kpi/manhour", element: <KPIManhourPage /> },
  { path: "/kpi/defect", element: <KPIDefectPage /> },
  { path: "/kpi/leadtimeOrder", element: <KPILeadTimeOrderPage /> },
  { path: "/kpi/powerUsage", element: <KPIPowerUsagePage /> },
  { path: "/kpi/uph", element: <KPIUPHPage /> },
  { path: "/kpi/rateOfOperation", element: <KPIRateOfOperationPage /> }
];

// 입출고관리 메뉴 Routes
const storageManagementMenuRoutes = [
  { path: "/shipment-request", element: <ShipmentRequestPage /> },
  { path: "/shipment-receipt", element: <ShipmentReceiptPage /> },
  { path: "/shipment-status", element: <ShipmentStatusPage /> }
]

// 설비모니터링 메뉴 Routes
const equipmentMonitoringRoutes = [
  { path: "/analysisMonitoring", element: <AnalysisMonitoringPage /> },
  { path: "/errorView", element: <ErrorViewPage /> },
  { path: "/maintenance", element: <MaintenancePage /> },
  { path: "/maintenanceList", element: <MaintenanceListPage /> },
  { path: "/maintenanceComplete", element: <MaintenanceCompletePage /> },
  { path: "/equipmentProblem", element: <EquipmentProblemPage /> },
  { path: "/dailyRegister", element: <DailyRegisterPage /> },
  { path: "/dailyList", element: <DailyListPage /> },
  { path: "/output", element: <OutputPage /> },
  { path: "/error", element: <ErrorPage /> },
  { path: "/power", element: <PowerPage /> },
  { path: "/idleTime", element: <IdleTimePage /> },
  { path: "/workTime", element: <WorkTimePage /> },
];
// 시스템 메뉴 Routes
const systemMenuRoutes = [
  { path: "/system", element: <SystemPage /> },
  { path: "/system-logs", element: <SystemLogsPage /> },
  { path: "/system-parameter", element: <SystemParameterPage /> }
];

const RoutePaths = [
  { path: "/dashboard", element: <MainDashboard /> },
  ...(customFunctions.ADD_USER_ACCESS_STATUS ? [{ path: "/userLogs", element: <UserLogsPage /> }] : []),
  { path: "/work-by-equipment", element: <WorkByEquipment /> },
  { path: "/work-by-production-plan", element: <WorkByProductionPlan /> },
  { path: "/work-by-row", element: <WorkByRows /> },
  ...(customFunctions.ADD_SHOW_OPERATION_OUTSOURCING_WORK ? [{
    path: "/operation-outsource-work-by-row", element:
      <CheckboxProvider>
        <WorkByOperationOutsource />
      </CheckboxProvider>
  }] : []),
  {
    path: "/lotForward", element:
      <LotTrackingProvider>
        <LotTrackingForwardPage />
      </LotTrackingProvider>
  },
  {
    path: "/lotReverce", element:
      <LotTrackingProvider>
        <LotTrackingRevercePage />
      </LotTrackingProvider>
  },
  { path: "/production-plan-with-works-form", element: <ProductionPlanCreateForm /> },
  { path: "/work-log", element: <WorkLogPage /> },
  ...(customFunctions.ADD_MONITORING_MENU_SETTING ||
    customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING ? [
    { path: "/overview", element: <OverViewPage /> }
  ] : []),
  ...(customFunctions.ADD_MONITORING_MENU_SETTING ||
    customFunctions.ADD_PMS_MENU_SETTING ||
    customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING ? [
    { path: "/monitoring", element: <MonitoringPage /> }
  ] : []),
  { path: "/purchaseOrder", element: <PurchaseOrderPage /> },
  { path: "/deliveryStatus", element: <DeliveryStatusPage /> },
  { path: "/purchaseOrderItems", element: <PurchaseOrderItemsPage /> },
  { path: "/orderItems", element: <OrderItemsPage /> },
  { path: "/stockPlan", element: <StockPlanPage /> },
  { path: "/transportation", element: <TransportationPage /> },
  ...(customFunctions.ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING ? [{ path: "/common-code", element: <CommonCodePage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING ? [{ path: "/authority", element: <AuthorityPage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING ? [{ path: "/ALCmodel", element: <ALCModelPage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_BOM_MENU_SETTING ? [{ path: "/bom", element: <BOMPage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING ? [{ path: "/bomList", element: <BOMListPage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_FILE_MENU_SETTING ? [{ path: "/document-file", element: <DocumentFilePage /> }] : []),
  { path: "/downtimeReasons-log", element: <DownTimeReasonsLogPage /> },
  ...(customFunctions.ADD_LACK_SAFETY_MENU_SETTING ? [{ path: "/lackSafety", element: <LackSafetyPage /> }] : []),
  ...(customFunctions.ADD_INVENTORY_LIFE_MENU_SETTING ? [{ path: "/inventoryLife", element: <InventoryLifePage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING ? [{ path: "/peripheral", element: <PeripheralPage /> }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_MACHINE_MENU_SETTING ? [{ path: "/machine", element: <MachinePage /> }] : []),
  ...(customFunctions.ADD_PMS_PRESS_MONITORING ||
    customFunctions.ADD_PMS_MENU_SETTING ? [
    { path: "/pressMonitoring/factoryMonitoring", element: <PressMonitoringFactoryPage /> }
  ] : []),
  { path: "/pressMonitoring/factoryMonitoring/pageSetting", element: <FactoryPageSetting /> },
  ...(customFunctions.ADD_DATA_DISPLAY_MENU_SETTING &&
    customFunctions.ADD_KPI_MENU_SETTING ? [
    { path: "/dataDisplay", element: <DataDisplayPage /> }
  ] : []),
  ...(customFunctions.ADD_DASHBOARD_EQUIPMENT_ANALYSIS ? [{ path: '/equipmentAnalysis', element: <EquipmentAnalysisPage /> }] : []),
  ...(customFunctions.ADD_DEFECT_STATISTICS_MENU_SETTING ? [{ path: "/quality/defect-statistics", element: <DefectStatisticsPage /> }] : []),
  ...(customFunctions.ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING ? [{ path: "/quality/work-standardList", element: <WorkStandardListPage /> }] : []),
  ...(customFunctions.ADD_CHANGE_LIST_MENU_SETTING ? [{ path: "/quality/change-list", element: <ChangeListPage /> }] : []),
  // { path: "/quality/change-register", element: <ChangeRegisterInfomationPage /> },
  // { path: "/quality/pre-warehouseList", element: <PreWareHouseListPage /> },
  ...(customFunctions.ADD_PERFORMANCE_MENU_SETTING ? [{ path: "/productionpreformance", element: <ProductionPerformancePage /> }] : []),
  ...(customFunctions.ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING ? [{ path: "/productionperformanceOutsourcing", element: <ProductionPerformanceOutsourcingPage /> }] : []),
  ...(customFunctions.ADD_PMS_MENU_SETTING ? pmsMenuRoutes : []),
  ...(customFunctions.ADD_KPI_MENU_SETTING ? kpiMenuRoutes : []),
  ...(customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING ? equipmentMonitoringRoutes : []),
  ...(customFunctions.ADD_SYSTEM_MANAGEMENT_MENU_SETTING ? systemMenuRoutes : []),
  ...(customFunctions.ADD_STORAGE_MANAGEMENT_MENU_SETTING ? storageManagementMenuRoutes : []),

  { path: "/wizard", element: <WizardForm /> },
  { path: "/form", element: <FormExample /> },
  { path: "/modal-multi", element: <ModalExample /> },

  { path: "/purchaseOrderItems-head-office", element: <PurchaseOrderItemsHeadOfficePage /> },
  { path: "/deliveryStatus-head-office", element: <DeliveryStatusHeadOfficePage /> },
  { path: "/deliveryList-company", element: <DeliveryListCompanyPage /> },
  { path: "/deliveryList-head-office", element: <DeliveryListHeadOfficePage /> },
  { path: "/order-head-office", element: <OrderHeadOfficePage /> },
  { path: "/purchaseOrderItems-supplier", element: <PurchaseOrderItemsSupplierPage /> },
  { path: "/deliveryStatus-supplier", element: <DeliveryStatusSupplierPage /> },
  { path: "/deliveryList-supplier", element: <DeliveryListSupplierPage /> },
  { path: "/shipment-request-supplier", element: <ShipmentRequestSupplierPage /> },
  { path: "/receipt-supplier", element: <ReceiptSupplierPage /> },
  { path: "/shipment-status-supplier", element: <ShipmentStatusSupplierPage /> },
  { path: "/label-supplier", element: <LabelSupplierPage /> },
  { path: "/shipment-return", element: <ShipmentReturnPage /> },
  { path: "/work-standard", element: <WorkStandardPage /> },
  { path: "/changePoint", element: <ChangePointPage /> },
  { path: "/changePointList", element: <ChangePointListPage /> },
  { path: "/receivingInspection", element: <ReceivingInspectionPage /> },
  { path: "/productionInspection", element: <ProductionInspectionPage /> },
  { path: "/work-complete", element: <WorkCompletePage /> },
  { path: "/receipt", element: <ReceiptPage /> },
  { path: "/barcodeLift", element: <BarcodeLiftPage /> },
  { path: "/barcodeMerge", element: <BarcodeMergePage /> },
];

// 기준정보 메뉴 Paths
const StandardInfoPath = [
  {
    path: "/items",
    element: <Item />,
    queryKeyFactory: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING ? items.getView : items.get,
    searchFieldsHeader: itemsHeader,
  },
  {
    path: "/equipment",
    element: <Equipment />,
    queryKeyFactory: equipments.get,
    searchFieldsHeader: equipmentsHeader,
    populate: ["works", "location"]
  },
  {
    path: "/defects",
    element: <Defects />,
    queryKeyFactory: defects.get,
    searchFieldsHeader: defectsHeader,
  },
  {
    path: "/operations",
    element: <Operations />,
    queryKeyFactory: operations.get,
    searchFieldsHeader: operationsHeader,
    populate: ["location"]
  },
  {
    path: "/routing",
    element: <Routing />,
    queryKeyFactory: routings.get,
    searchFieldsHeader: routingsHeader,
    populate: ["item", "operation", "mold"]
  },
  {
    path: "/users",
    element: <Users />,
    queryKeyFactory: users.get,
    searchFieldsHeader: usersHeader,
  },
  {
    path: "/site",
    element: <Site />,
    queryKeyFactory: sites.get,
    searchFieldsHeader: sitesHeader
  },
  {
    path: "/location",
    element: <LocationPage />,
    queryKeyFactory: locations.get,
    searchFieldsHeader: locationsHeader,
  },
  {
    path: "/routing-outsource",
    element: <RoutingOutsourcePage />,
    queryKeyFactory: routingOutsources.get,
    searchFieldsHeader: routingOutsourcesHeader,
    populate: ["location"]
  },
  {
    path: "/mold",
    element: <MoldPage />,
    queryKeyFactory: molds.get,
    searchFieldsHeader: moldsHeader,
  },
  {
    path: "/moldStock",
    element: <MoldStockPage />,
    queryKeyFactory: moldStocks.get,
    searchFieldsHeader: moldStocksHeader
  },
  {
    path: "/abstract-item",
    element: <AbstractItemPage />,
    queryKeyFactory: abstractItems.get,
    searchFieldsHeader: abstractItemsHeader
  },
  {
    path: "/item-category",
    element: <ItemCategoryPage />,
    queryKeyFactory: itemCategories.get,
    searchFieldsHeader: itemCategoriesHeader
  },
  {
    path: "/downtimeReasons",
    element: <DowntimeReasonsPage />,
    queryKeyFactory: downtimeReasons.get,
    searchFieldsHeader: downtimeReasonsHeader
  },
  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING ? [
    {
      path: "/grade",
      element: <GradePage />,
      queryKeyFactory: grades.get,
      searchFieldsHeader: gradesHeader
    }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING ? [
    {
      path: "/department",
      element: <DepartmentPage />,
      queryKeyFactory: department.get,
      searchFieldsHeader: departmentHeader
    }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING ? [
    {
      path: "/partner",
      element: <PartnerPage />,
      queryKeyFactory: partners.get,
      searchFieldsHeader: partnersHeader
    }] : []),
  ...(customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING ? [
    {
      path: "/bomSearch",
      element: <BOMSearchPage />,
      queryKeyFactory: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING ? items.getView : items.get,
      searchFieldsHeader: itemsBomHeader
    }] : []),

  // 추가 라우트 정의 ...
  ...(customFunctions.ADD_BEST_PRACTICE_TEST_MENU_SETTING ? [
    {
      path: "/test-standard-info-best-practice",
      element: <StandardInfoBestPracticePage />,
      queryKeyFactory: items.get,
      searchFieldsHeader: itemsHeader
    }] : []),
  // 추가 라우트 정의 ...
];

const InventoryControlPath = [
  {
    path: "/inventory",
    element: <InventoryPage />,
    queryKeyFactory: inventories.betweenGet,
    searchFieldsHeader: inventoriesHeader,
    populate: ["lot", "location", "item", "grade", "abstractItem", "itemCategoriesItem"],
    isUseParams: true,
    isUseDate: true
  },
  {
    path: "/ledgersAll",
    element: <LedgerAllPage />,
    queryKeyFactory: ledgers.get,
    searchFieldsHeader: LedgersHeader,
    populate: ["item", "location", "name"],
    isUseDate: true
  },
  {
    path: "/wipInventory",
    element: <WipInventoryPage />,
    queryKeyFactory: wipInventory.get,
    searchFieldsHeader: wipInventoriesHeader,
    populate: ["productionPlans", "itemUnit"]
  },
  {
    path: "/wmsLogs",
    element: <WmsLogsPage />,
    queryKeyFactory: wmsLog.get,
    searchFieldsHeader: wmsLogsHeader,
    populate: ["locationName", "lot"]
  },
]

const QualityControlPath = [
  ...(customFunctions.ADD_QUALITY_MENU_SETTING ? [
    {
      path: "/inspectSpecification",
      element: <InspectSpecificationPage />,
      queryKeyFactory: specifications.get,
      searchFieldsHeader: specificationsHeader,
      populate: ["lot"]
    }] : []),
  ...(customFunctions.ADD_QUALITY_MENU_SETTING ? [
    {
      path: "/inspectionRecords",
      element: <InspectionRecordsPage />,
      queryKeyFactory: inspections.get,
      searchFieldsHeader: specificationsHeader,
      populate: ["lot"],
    }] : []),
]

const purchaseOrderPath = [
  // 발주 완료 페이지
  ...(customFunctions.ADD_ORDER_COMPLETE_MENU_SETTING ? [
    {
      path: "/orderComplete",
      element: <OrderCompletePage />,
      queryKeyFactory: purchaseOrders.complete,
      searchFieldsHeader: orderHeader,
      populate: ["location", "user", "partner", "purchaseOrderItems"],
    }
  ] : []),
  // 발주 페이지
  {
    path: "/order",
    element: <OrderPage />,
    queryKeyFactory: purchaseOrders.order,
    searchFieldsHeader: orderHeader,
    populate: ["location", "user", "partner", "purchaseOrderItems"],
  },
  ...(customFunctions.ADD_INVOICE_STATEMENT_MENU_SETTING ? [
    // 거래명세서 페이지
    {
      path: "/invoiceStatement",
      element: <InvoiceStatementPage />,
      queryKeyFactory: invoice.get,
      searchFieldsHeader: invoiceStatementHeader,
      populate: ["populateLocationName", "populateUserName", "populateNumberOfInvoiceItems", "invoiceItems"],
    },
    // 거래명세서 완료 페이지
    {
      path: "/invoiceStatementComplete",
      element: <InvoiceStatementCompletePage />,
      queryKeyFactory: invoice.complete,
      searchFieldsHeader: invoiceStatementHeader,
      populate: ["populateLocationName", "populateUserName", "populateNumberOfInvoiceItems", "invoiceItems"],
    }
  ] : [])
]

function RoutesConfig() {
  const location = useLocation();
  const auth = useCheckAuth();

  return (
    <Routes location={location}>
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/dashboard"}>
              <Login />
            </ProtectedRoute>
          }
        />
        {RoutePaths.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                {route.element}
              </ProtectedRoute>
            }
          />
        ))}
        {StandardInfoPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                >
                  {route.element}
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        {QualityControlPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                >
                  {route.element}
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        {
          InventoryControlPath.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                  <StandardLayoutProvider
                    key={route.path}
                    populate={route.populate}
                    queryKeyFactory={route.queryKeyFactory}
                    searchFieldsHeader={route.searchFieldsHeader}
                    isUseParams={route.isUseParams}
                    isUseDate={route.isUseDate}
                  >
                    {route.element}
                  </StandardLayoutProvider>
                </ProtectedRoute>
              }
            />
          ))
        }
        {
          purchaseOrderPath.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                  <StandardLayoutProvider
                    key={route.path}
                    populate={route.populate}
                    queryKeyFactory={route.queryKeyFactory}
                    searchFieldsHeader={route.searchFieldsHeader}
                  >
                    {route.element}
                  </StandardLayoutProvider>
                </ProtectedRoute>
              }
            />
          ))
        }
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default RoutesConfig;
