import { customFunctions } from "@/config/customFunction";
import { itemType } from "@/constants/items";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { GradesAutoComplete } from "@/features/ui/autoComplete/grade/grades-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import {
    Checkbox,
    Flex,
    Select,
    Text,
    TextInput
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ItemsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

interface ItemsFormProps {
    formData: UseFormReturnType<Partial<ItemsGetRequest>>;
}

export const ItemsForm = ({ formData }: ItemsFormProps) => {
    const { t } = useTranslation();
    const lnItemType = itemType.map((item: any) => ({
        ...item,
        label: t(item.label),
    }));

    return (
        <Flex direction="column" gap="sm" p="md" w="100%">
            <Flex gap="xl" align="center">
                <Flex gap="md" align="center">
                    <Text fw={700} fz="lg" color="red" w="1rem">*</Text>
                    <Text fw={700} fz="lg" color="red" w="10rem">품목군</Text>
                </Flex>
                <Select
                    placeholder="품목군을 선택해 주세요."
                    data={lnItemType}
                    size="md"
                    w="20rem"
                    {...formData.getInputProps("itemType")}
                />
            </Flex>
            <Flex gap="xl" align="center">
                <Flex gap="md" align="center">
                    <Text fw={700} fz="lg" color="red" w="1rem">*</Text>
                    <Text fw={700} fz="lg" color="red" w="10rem">품목명</Text>
                </Flex>
                <TextInput
                    placeholder="품목명"
                    size="md"
                    w="20rem"
                    {...formData.getInputProps("name")}
                />
            </Flex>
            {
                customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING && (
                    <Flex gap="xl" align="center">
                        <Flex gap="md" align="center">
                            <Text fw={700} fz="lg" color="red" w="1rem">*</Text>
                            <Text fw={700} fz="lg" color="red" w="10rem">등급</Text>
                        </Flex>
                        <GradesAutoComplete
                            placeholder="등급을 선택해 주세요."
                            size="sm"
                            w="20rem"
                            {...formData.getInputProps("gradeCode")}
                        />
                    </Flex>
                )
            }
            <Flex gap="xl" align="center">
                <Text fw={700} c="dimmed" w="12rem">
                    Color
                </Text>
                <ItemCategoryAutoComplete
                    placeholder="Color를 선택해 주세요."
                    size="sm"
                    w="20rem"
                    {...formData.getInputProps("itemCategoryName")}
                />
            </Flex>
            <Flex gap="xl" align="center">
                <Text fw={700} c="dimmed" w="12rem">
                    형태
                </Text>
                <AbstractItemAutoComplete
                    placeholder="형태를 선택해 주세요."
                    size="sm"
                    w="20rem"
                    {...formData.getInputProps("abstractItemCode")}
                />
            </Flex>
            <Flex gap="xl" align="center">
                <Text fw={700} c="dimmed" w="12rem">
                    단위
                </Text>
                <Select
                    data={["kg", "Bag", "EA"]}
                    placeholder="단위를 선택해 주세요."
                    size="sm"
                    w="20rem"
                    {...formData.getInputProps("unitText")}
                />
            </Flex>
            <Flex gap="xl" align="center">
                <Text fw={700} c="dimmed" w="12rem">
                    재고 LOT 관리 기준
                </Text>
                <Flex justify="space-between" align="center" gap="xl">
                    <Checkbox label="이름" {...formData.getInputProps("usesLotName")} />
                    <Checkbox
                        label="유효기한"
                        {...formData.getInputProps("usesLotExpiration")}
                    />
                </Flex>
            </Flex>
        </Flex >
    );
};
