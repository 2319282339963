import { customFunctions } from "@/config/customFunction";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Text } from "@mantine/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DefectTypeItem } from "../ui/DefectTypeSet";

type PageKeyType = 'equipment' | 'plan';

interface WorkTableHeadProps {
    defectTypes: DefectTypeItem[] | null;
    pageKey: PageKeyType;
    formReset?: boolean;
}

export const WorkTableHead = ({ defectTypes, pageKey, formReset }: WorkTableHeadProps) => {

    const isPlan = useMemo(() => pageKey === 'plan', [pageKey]);

    const columns = useMemo(() => [
        {
            labelComponent: (
                <Text fz="sm" fw={500} c="black" ta="center" span>
                    작업순서
                </Text>
            ),
            index: 1,
            condition: isPlan
        },
        {
            labelComponent: (
                <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fz="sm" fw={500} c="black" ta="center" span>
                        최근 작업일
                    </Text>
                </Flex>
            ),
            index: 2,
            condition: isPlan
        },
        {
            labelComponent: (
                <Text fz="sm" fw={500} c="black" ta="center" span>
                    작업번호
                </Text>
            ),
            index: 3,
            condition: isPlan && customFunctions.ADD_WORK_ID_COLUMN
        },
        {
            labelComponent: (
                <Text fz="sm" fw={500} c="black" ta="center" span>
                    사외 외주 여부
                </Text>
            ),
            index: 4,
            condition: isPlan && customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN
        },
        {
            labelComponent: (
                <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fz="sm" fw={500} c="black" ta="center">
                        라인명
                    </Text>
                    <Text fz="sm" fw={500} c="black" ta="center">
                        설비명
                    </Text>
                </Flex>
            ),
            index: 5,
            condition: true
        },
        {
            labelComponent: (
                <Flex justify="center" align="center">
                    <Text fz="sm" fw={500} c="black" ta="center" span>
                        비고
                    </Text>
                </Flex>
            ),
            index: 6,
            condition: isPlan && customFunctions.ADD_DESCRIPTION_COLUMN
        },
        {
            labelComponent: (
                <Flex align="center" direction="column" wrap="wrap">
                    <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
                        <Text fz="sm" fw={500} c="black">
                            AI 예측 품목(라우팅코드)
                        </Text>
                        <Text fz="sm" fw={500} c="black">
                            품목명(품목코드)
                        </Text>
                        <Text fz="sm" fw={500} c="black" ta="center">
                            진행률
                        </Text>
                    </Flex>
                </Flex>
            ),
            index: 7,
            condition: true
        },
        {
            labelComponent: (
                <Flex align="center" direction="column" wrap="wrap">
                    <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
                        <Flex gap="xs">
                            <Text fz="sm" fw={500} c={theme.colors?.blue?.[9]}>
                                계획수량
                            </Text>
                            <Text>/</Text>
                            <Text fz="sm" fw={500} c={theme.colors?.orange?.[9]}>
                                생산 잔량
                            </Text>
                        </Flex>
                        <Flex gap="xs">
                            <Text fz="sm" fw={500} c={theme.colors?.teal?.[9]} ta="center">
                                총생산수량
                            </Text>
                            <Text>/</Text>
                            <Text fz="sm" fw={500} c={theme.colors?.red?.[9]} ta="center">
                                불량수량
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            ),
            index: 8,
            condition: true
        },
        {
            labelComponent: (
                <Text fz="sm" fw={500} c="black" ta="center">
                    완료수량
                </Text>
            ),
            index: 9,
            condition: true
        }
    ].filter(col => col.condition).map((col, index) => ({ ...col, index: index + 1 })),
        [
            isPlan, customFunctions.ADD_WORK_ID_COLUMN, customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN, customFunctions.ADD_DESCRIPTION_COLUMN
        ]);

    const refs = useRef<(HTMLTableHeaderCellElement | null)[]>([]);
    const [widths, setWidths] = useState<number[]>(new Array(columns.length).fill(0));

    const state = useWorkState();
    const actions = useWorkActions();
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const publish = usePub();

    useEffect(() => {
        setAllChecked(state.works.length === state.tempWorks.length);
    }, [state.works, state.tempWorks]);

    useEffect(() => {
        const newWidths = refs.current.map((ref) => (ref ? ref.offsetWidth : 0));
        setWidths(newWidths);
    }, [state.date, state.works, state.tempWorks, columns]);

    useSub('check', () => {
        setAllChecked(state.works.length === state.tempWorks.length);
    });

    useSub('unCheck', () => {
        setAllChecked(false);
    });

    useEffect(() => {
        if (formReset) {
            setAllChecked(false);
        }
    }, [formReset, allChecked]);


    const calculateLeftWidth = useCallback((index: number) => {
        return refs.current.slice(0, index).reduce((acc, ref) => acc + (ref ? ref.offsetWidth : 0), 0);
    }, []);

    return (
        <thead>
            <tr>
                <Th leftWidth={0} ref={(el) => (refs.current[0] = el)}>
                    <Flex justify="center">
                        <Checkbox
                            checked={allChecked}
                            onClick={(e) => {
                                if (e.currentTarget.checked) {
                                    publish('checkAll');
                                    actions.setWorks((prevWorks) => state.tempWorks);
                                    setAllChecked(true);
                                }
                                else {
                                    publish('unCheckAll');
                                    actions.setWorks((prevWorks) => []);
                                    setAllChecked(false);
                                }
                            }}
                        />
                    </Flex>
                </Th>
                {
                    columns.map((columns) => {
                        return (
                            <Th
                                key={columns.index}
                                leftWidth={calculateLeftWidth(columns.index)}
                                ref={(el) => (refs.current[columns.index] = el)}
                            >
                                {columns.labelComponent}
                            </Th>
                        )
                    })
                }

                {defectTypes?.map((defect) => (
                    <th key={defect.value}>
                        <Text fz="sm" fw={500} c="black" ta="center">
                            {defect.label}
                        </Text>
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const Th = React.memo(
    styled.th<{ leftWidth?: number }>`
        position: sticky;
        background-color: white;
        border-right: 0.0625rem solid rgb(222, 226, 230);
        border-bottom: 0.0625rem solid rgb(222, 226, 230);
        left: ${(props) => props.leftWidth ?? 0}px;
    `,
    (prevProps, nextProps) => prevProps.leftWidth === nextProps.leftWidth
);