import { consumeType, routingType } from "@/constants/routings";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import styled from "@emotion/styled";
import { Badge, Button, Flex, NumberInput, Select, Table } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconPlus, IconTrashX } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { RoutingFormProps } from "../ItemsCreateForm";

interface RoutingsFormProps {
  formData: UseFormReturnType<Partial<RoutingFormProps>>;
}

export const RoutingsForm = ({ formData }: RoutingsFormProps) => {

  const { t } = useTranslation();

  const lnRoutingType = routingType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const handleClickAdd = () => {
    formData.insertListItem("routingRows", {
      seq: 1, // 라우팅순서
      operationCode: "", // 공정코드
      code: `routing_${dayjs().format("YYYYMMDD_HHmmss").toString()}`, // 라우팅코드
      routingType: "NORMAL", // 라우팅유형 (라우팅 타입)
      consumeType: "", // 소모 시점
      routingBoms: [
        {
          itemCode: "",
          quantity: "",
        },
      ], // 라우팅 BOM
    });
  };

  const handleClickDelete = (index: number) => {
    formData.removeListItem("routingRows", index);
  };

  return (
    <Flex direction="column" gap="md">
      <Table withBorder withColumnBorders>
        <thead>
          <tr>
            <Th width={6}>시퀀스</Th>
            <Th width={18}>공정</Th>
            <Th width={11}>라우팅타입</Th>
            <Th width={11}>소모 시점</Th>
            <Th width={8}>비고</Th>
          </tr>
        </thead>
        <tbody>
          {formData.values.routingRows &&
            formData.values.routingRows.map((_, index) => (
              <tr key={index}>
                <td>
                  <NumberInput
                    {...formData.getInputProps(`routingRows.${index}.seq`)}
                    min={1}
                  /></td>
                <td><OperationAutoComplete {...formData.getInputProps(`routingRows.${index}.operationCode`)} /></td>
                <td><Badge color="green.7">일반</Badge></td>
                <td><Select data={lnConsumeType} {...formData.getInputProps(`routingRows.${index}.consumeType`)} /></td>
                <td>
                  <Button
                    leftIcon={<IconTrashX />}
                    disabled={!(formData.values.routingRows && formData.values.routingRows.length > 1)}
                    onClick={() => handleClickDelete(index)}
                    color="red"
                    w="100%"
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <Flex w="100%" justify="center">
        <Button
          variant="subtle"
          c="black"
          leftIcon={<IconPlus />}
          onClick={() => handleClickAdd()}
        >
          새로운 라우팅 추가
        </Button>
      </Flex>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
`;
